import axios from "axios"
import { Message } from 'element-ui';

//创建一个axios实例
const instance = axios.create();

instance.defaults.baseURL = 'https://jdhltech.com'
instance.defaults.timeout = 3000
instance.defaults.withCredentials = true


instance.interceptors.response.use(
    function (response) {
        const res = response.data;
        // console.log(res)
        const code = res.code;
        if (code === 300) {
            Message({
                showClose: true,
                message: res.message,
                type: 'error'
            })
            sessionStorage.clear();
            return response;
        }

        return response;
    }
)

export default instance