<template>
  <div>
    <div class="titleA">点击图片可查看相关项目案例</div>
    <el-row
      class="business"
      type="flex"
      justify="center"
      align="middle"
      :gutter="30"
    >
      <el-col :span="6"
        ><img
          id="BusinessA"
          src="../assets/Web.jpg"
          @click="toWebProjOverview()"
        />
      </el-col>
      <el-col :span="6"
        ><img
          id="BusinessB"
          src="../assets/单片机.jpeg"
          @click="toHardwareProjOverview()"
        />
      </el-col>
      <el-col :span="6">
        <img id="BusinessC" src="../assets/上位机软件.jpg"
      /></el-col>
    </el-row>

    <el-row class="businessText" type="flex" justify="center" :gutter="30">
      <el-col :span="6">
        <!-- 设置div类ref，目的为测量实际列宽 -->
        <div class="ref">
          <div class="textA">
            网站建设、Web平台前后端开发、小程序/APP开发，H5页面开发相关的技术服务，例如公司官网、后台管理、任务管理、告警、数据互通这些平台或网站
          </div>
        </div></el-col
      >
      <el-col :span="6"
        ><div class="textA">
          承接基于单片机STM32的固件开发，外围电路设计。并可提供含上位机软件与机械结构设在内的整包项目服务。
        </div></el-col
      ><el-col :span="6"
        ><div class="textA">
          桌面软件开发（上位机软件、应用程序.exe）
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
export default {
  name: "MainBusiness",
  mounted() {
    this.dootedCenter();
    window.addEventListener("resize", this.dootedCenter, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.dootedCenter);
  },
  methods: {
    dootedCenter() {
      // 获取外层容器的宽度
      var colWidth = document
        .getElementsByClassName("ref")[0]
        .getBoundingClientRect().width;
      // console.log(colWidth);

      // 通过Class定义多个业务文本类变量
      var A = document.getElementsByClassName("textA")[0];
      var B = document.getElementsByClassName("textA")[1];
      var C = document.getElementsByClassName("textA")[2];
      // 通过ID定义多个业务图片类变量
      var BusinessImgA = document.getElementById("BusinessA");
      var BusinessImgB = document.getElementById("BusinessB");
      var BusinessImgC = document.getElementById("BusinessC");
      // 计算图片的最大高度（300px/500px的宽高比），因需要同一高度，因此统一使用“A”的宽度数据来计算高度值
      BusinessImgA.style.maxHeight =
        A.getBoundingClientRect().width * 0.6 + "px";
      BusinessImgB.style.maxHeight =
        A.getBoundingClientRect().width * 0.6 + "px";
      BusinessImgC.style.maxHeight =
        A.getBoundingClientRect().width * 0.6 + "px";
      // 计算使文本居中的marginLeft值
      A.style.marginLeft =
        (colWidth - A.getBoundingClientRect().width) / 2 + "px";
      B.style.marginLeft =
        (colWidth - B.getBoundingClientRect().width) / 2 + "px";
      C.style.marginLeft =
        (colWidth - C.getBoundingClientRect().width) / 2 + "px";

      // 验证图片宽高比（3：5 → 60%）
      // console.log(BusinessImgA.getBoundingClientRect());
      // console.log(BusinessImgB.getBoundingClientRect());
      // console.log(BusinessImgC.getBoundingClientRect());
    },
    toWebProjOverview() {
      this.$router.push("/weboverview");
    },
    toHardwareProjOverview() {
      this.$router.push("/hardwareoverview");
    },
  },
};
</script>

<style scoped>
.business {
  text-align: center;
}
.business img {
  height: 300px;
  width: 500px;
  max-width: 100%;
  cursor: pointer;
}
.businessText {
  text-align: left;
}
.ref {
  width: 100%;
}
.titleA {
  font-family: "Times New Roman", "STKaiti";
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 50px;
  margin-top: 80px;
  margin-left: 150px;
  color: #7c2ce4;
}
.textA {
  font-family: "Times New Roman", "STKaiti";
  font-size: 24px;
  color: #201926;
  width: 500px;
  max-width: 100%;
}
.el-row {
  margin-bottom: 5px;
}
/* 最后一行无需设置行间距 */
.el-row:last-child {
  margin-bottom: 0;
}
</style>
