<template>
  <div>
    <div style="margin-bottom: 100px">
      <img src="../assets/pic2.jpg" style="width: 100%" />
    </div>
    <div class="support">
      <div class="title">主营业务&nbsp;&nbsp;&nbsp;Business</div>
    </div>
    <!-- 引入主营业务模块 -->
    <MainBusiness style="padding: 0 20px" />
    <!-- 引入滚动窗组件 -->
    <!-- <RotationChart style="margin: 100px 0" /> -->

    <div class="support">
      <div class="title">联系我们&nbsp;&nbsp;&nbsp;Contact Us</div>
    </div>
    <div class="inlayer">
      <div class="Member">
        <el-row
          ><el-col :span="6"
            ><div class="contact">
              <el-form :model="form" ref="ContactInfo">
                <div style="display: inline-block; width: 100%">
                  <el-input
                    placeholder="标题"
                    v-model="form.inputTitle"
                    clearable
                    style="width: 49%; margin-right: 2%"
                  >
                  </el-input>
                  <el-input
                    placeholder="联系电话"
                    v-model="form.inputPhoneNumber"
                    clearable
                    style="width: 49%"
                  >
                  </el-input>
                </div>
                <div style="margin: 10px 0">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 7, maxRows: 12 }"
                    placeholder="请输入内容"
                    v-model="form.textDetail"
                    style="width: 100%"
                  >
                  </el-input>
                </div>
              </el-form>
              <el-button
                style="
                  background: #4390d8;
                  color: white;
                  font-family: 'Times New Roman', 'STKaiti';
                  font-weight: normal;
                "
                @click="sendMessage"
                >提交</el-button
              >
            </div></el-col
          ><el-col :span="16" :offset="2">
            <el-row>
              <el-col :span="14" :offset="4">
                <el-row>
                  <img
                    src="../assets/name.png"
                    style="width: 20px; margin: 4px 13px 0 100px; float: left"
                    alt
                  />
                  <div class="text">联系人：林霈</div></el-row
                ><el-row
                  ><img
                    src="../assets/phone.png"
                    style="width: 20px; margin: 6px 12px 0 100px; float: left"
                    alt
                  />
                  <div class="text">联系电话: +86-18588474315</div>
                </el-row>
                <el-row>
                  <img
                    src="../assets/post.png"
                    style="width: 20px; margin: 8px 12px 0 100px; float: left"
                    alt
                  />
                  <div class="text">邮箱: linpei@jdhltech.com</div>
                </el-row>
              </el-col>
              <el-col :span="5" :offset="1">
                <img src="../assets/qrcode_lp.png" alt style="width: 100px"
              /></el-col>
            </el-row>
            <br /><br /><br />
            <el-row>
              <el-col :span="14" :offset="4">
                <el-row>
                  <img
                    src="../assets/name.png"
                    style="width: 20px; margin: 4px 13px 0 100px; float: left"
                    alt
                  />
                  <div class="text">联系人：柳培栋</div></el-row
                ><el-row
                  ><img
                    src="../assets/phone.png"
                    style="width: 20px; margin: 6px 12px 0 100px; float: left"
                    alt
                  />
                  <div class="text">联系电话: +86-13686865073</div>
                </el-row>
                <el-row>
                  <img
                    src="../assets/post.png"
                    style="width: 20px; margin: 8px 12px 0 100px; float: left"
                    alt
                  />
                  <div class="text">邮箱: liupeidong@jdhltech.com</div>
                </el-row>
              </el-col>
              <el-col :span="5" :offset="1">
                <img src="../assets/qrcode_lpd.png" alt style="width: 100px"
              /></el-col>
            </el-row>
            <br /><br /><br />
            <el-row>
              <el-col :span="14" :offset="4">
                <el-row>
                  <img
                    src="../assets/name.png"
                    style="width: 20px; margin: 4px 13px 0 100px; float: left"
                    alt
                  />
                  <div class="text">联系人：吴柏维</div></el-row
                ><el-row
                  ><img
                    src="../assets/phone.png"
                    style="width: 20px; margin: 6px 12px 0 100px; float: left"
                    alt
                  />
                  <div class="text">联系电话: +86-17603050125</div>
                </el-row>
                <el-row>
                  <img
                    src="../assets/post.png"
                    style="width: 20px; margin: 8px 12px 0 100px; float: left"
                    alt
                  />
                  <div class="text">邮箱: wubowei@jdhltech.com</div>
                </el-row>
              </el-col>
              <el-col :span="5" :offset="1">
                <img src="../assets/qrcode_wbw.png" alt style="width: 100px"
              /></el-col> </el-row
          ></el-col>
        </el-row>
        <br /><br /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
// import RotationChart from "../components/RotationChart.vue";
import MainBusiness from "../components/Business.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "HomePage",
  components: { MainBusiness },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        inputTitle: "",
        inputPhoneNumber: "",
        textDetail: "",
      },
    };
  },

  computed: {
    ...mapState({
      successed: (state) => state.ContactInfo.successed,
      msg: (state) => state.ContactInfo.msg,
    }),
  },

  methods: {
    ...mapActions({
      sendContactInfo: "ContactInfo/sendMessage",
    }),

    // 发送邮件
    sendMessage() {
      if (this.form.inputTitle == "") {
        this.$message.warning("标题不能为空 !");
        return;
      }
      this.$refs["ContactInfo"].validate((valid) => {
        if (valid) {
          this.sendContactInfo(this.form).then(() => {
            if (this.successed) {
              this.dialogFormVisible = false;
              this.$message({
                message: "发送成功",
                type: "success",
              });
              this.clearFormInfo(this.form);
            } else {
              this.$message({
                message: this.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    clearFormInfo(formInfo) {
      Object.keys(formInfo).forEach((key) => {
        formInfo[key] = "";
      });
    },
  },
};
</script>

<style scoped>
ul li {
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.support {
  background: #201926;
  padding: 50px 46px;
  margin-top: 80px;
}
.inlayer {
  float: left;
  margin-top: 80px;
  width: 100%;
}
.support .title {
  font-family: "Times New Roman", "STKaiti";
  font-size: 34px;
  font-weight: bold;
  margin-left: 100px;
  color: white;
}
.inlayer .text {
  font-family: "Times New Roman", "STKaiti";
  font-size: 24px;
  color: #201926;
  float: left;
}
.contact {
  width: 500px;
  margin: 0 0 50px 100px;
}
.el-row {
  margin-bottom: 5px;
}
/* 最后一行无需设置行间距 */
.el-row:last-child {
  margin-bottom: 0;
}
</style>
