import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'

Vue.use(VueRouter)

// 解决路由地址重复的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('../views/RootView.vue'),
    redirect: "/Index",
  },
  {
    path: '/Index',
    name: 'MainPage',
    component: () => import('../pages/MainPage.vue'),
    redirect: "/Home",
    children: [
      {
        path: '/Home',
        name: 'home',
        component: Home
      },
      {
        path: '/weboverview',
        name: 'WebProjOverview',
        component: () => import('../pages/WebProjOverview.vue'),
      },
      {
        path: '/hardwareoverview',
        name: 'HardwareProjOverview',
        component: () => import('../pages/HardwareProjOverview.vue'),
      },
      {
        path: '/companyprofile',
        name: 'CompanyProfile',
        component: () => import('../pages/CompanyProfile.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router