import axios from "../util/axios"

export const ContactInfo = ({
    namespaced: true,
    state: {
        successed: "",
        msg: ""
    },
    getters: {
    },
    mutations: {
        success(state, data) {
            if (data.code === 200) {
                state.successed = true;
                state.msg = data.message
            } else {
                state.successed = false;
                state.msg = data.message
            }
        },

        error(state, error) {
            console.log(error)
            state.successed = false;
            state.msg = error.message
        },
    },
    actions: {
        //添加账户
        async sendMessage(context, message) {
            try {
                const res = await axios.post("/contact/sendMsg", {
                    'title': message.inputTitle,
                    'phoneNumber': message.inputPhoneNumber,
                    'detail': message.textDetail
                });
                context.commit('success', res.data);
            } catch (error) {
                context.commit('error', error);
            }
        },
    },
    modules: {
    }
})